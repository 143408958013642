// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contactPage.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/FAQS.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-folleto-js": () => import("./../../../src/pages/folleto.js" /* webpackChunkName: "component---src-pages-folleto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reloj-compra-js": () => import("./../../../src/pages/reloj/compra.js" /* webpackChunkName: "component---src-pages-reloj-compra-js" */),
  "component---src-pages-reloj-comprav-1-js": () => import("./../../../src/pages/reloj/comprav1.js" /* webpackChunkName: "component---src-pages-reloj-comprav-1-js" */),
  "component---src-pages-teleasistencia-js": () => import("./../../../src/pages/teleasistencia.js" /* webpackChunkName: "component---src-pages-teleasistencia-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/termsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

